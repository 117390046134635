import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Col, Row, Form } from 'antd';
import { InputRenderer, TabCollapse } from '../../components';
import {
    TitleInput,
    CreateProgramInput,
    MainClassInput,
    ClassInput,
    SubClassInput,
    TypeOfParticipationInput,
    BusinessTypeInput,
    UnderwritingYearInput,
    PeriodStartInput,
    PeriodEndInput,
    CountryGroupsInput,
    CountriesInput,
    LifecycleInput,
    CurrencyInput,
    CedentInput,
    InsuredInput,
    BrokerInput,
    ReinsurerInput,
    ReportUnitsInput
} from '../../tab_inputs/GeneralInformationTabInputs';
import { TabProps } from '../../componentTypes';
import { InstanceUtils, StyleUtils } from '../../misc';
import { useTabForm, useLayoutRendererStyle } from '../../hooks';
import './GeneralInformationTabRenderer.less';

const InputIds = {
    Title: 'Title',
    MainClass: 'MainClass',
    Class: 'Class',
    SubClass: 'SubClass',
    TypeOfParticipation: 'TypeOfParticipation',
    Lifecycle: 'Lifecycle',
    UnderwritingYear: 'UnderwritingYear',
    PeriodStart: 'PeriodStart',
    PeriodEnd: 'PeriodEnd',
    Currency: 'Currency',
    Countries: 'Countries',
    CountryGroups: 'CountryGroups',
    Cedent: 'Cedent',
    Reinsurer: 'Reinsurer',
    CreateProgram: 'CreateProgram',
    ReportUnits: 'ReportUnits',
    BusinessType: 'BusinessType',
    Insured: 'Insured',
    Broker: 'Broker'
} as const;

const GeneralInformationTabRenderer: React.FC<TabProps> = ({ tab, addFormRef }: TabProps) => {
    const { form, formKey, onValuesChange } = useTabForm(tab, addFormRef);

    const rendererRef = React.useRef<HTMLDivElement>(null);

    const style = useLayoutRendererStyle(tab.id, rendererRef);

    const getInputById = React.useCallback((id: string) => tab.inputs.find(input => input.id === id), [tab.inputs]);

    const inputs = React.useMemo(
        () => ({
            titleInput: getInputById(InputIds.Title),
            createProgramInput: getInputById(InputIds.CreateProgram),
            mainClassInput: getInputById(InputIds.MainClass),
            classInput: getInputById(InputIds.Class),
            subClassInput: getInputById(InputIds.SubClass),
            businessTypeInput: getInputById(InputIds.BusinessType),
            typeOfParticipationInput: getInputById(InputIds.TypeOfParticipation),
            underwritingYearInput: getInputById(InputIds.UnderwritingYear),
            periodStartInput: getInputById(InputIds.PeriodStart),
            periodEndInput: getInputById(InputIds.PeriodEnd),
            countryGroupsInput: getInputById(InputIds.CountryGroups),
            countriesInput: getInputById(InputIds.Countries),
            lifecycleInput: getInputById(InputIds.Lifecycle),
            currencyInput: getInputById(InputIds.Currency),
            insuredInput: getInputById(InputIds.Insured),
            reinsurerInput: getInputById(InputIds.Reinsurer),
            reportUnitsInput: getInputById(InputIds.ReportUnits),
            cedentInput: getInputById(InputIds.Cedent),
            brokerInput: getInputById(InputIds.Broker)
        }),
        [getInputById]
    );

    const hasClassification = React.useMemo(
        () => !!(inputs.mainClassInput || inputs.classInput || inputs.subClassInput),
        [inputs.classInput, inputs.mainClassInput, inputs.subClassInput]
    );

    if (!InstanceUtils.isBasicTab(tab)) {
        return null;
    }

    return (
        <div ref={rendererRef} className="general-information-tab-renderer custom-scroll" style={style}>
            <Form id={tab.id} key={formKey} form={form} onValuesChange={onValuesChange}>
                <TabCollapse tab={tab}>
                    <Row gutter={10}>
                        <Col style={StyleUtils.getTabColumnStyle(40)}>
                            <InputRenderer input={inputs.titleInput} component={TitleInput} />
                        </Col>

                        <Col style={StyleUtils.getTabColumnStyle(20)}>
                            <InputRenderer input={inputs.createProgramInput} component={CreateProgramInput} />
                        </Col>

                        <Col style={StyleUtils.getTabColumnStyle(40)} />
                    </Row>

                    {hasClassification && (
                        <Row gutter={10}>
                            <Col style={StyleUtils.getTabColumnStyle(20)}>
                                <InputRenderer input={inputs.mainClassInput} component={MainClassInput} />
                            </Col>

                            <Col style={StyleUtils.getTabColumnStyle(40)}>
                                <InputRenderer input={inputs.classInput} component={ClassInput} />
                            </Col>

                            <Col style={StyleUtils.getTabColumnStyle(40)}>
                                <InputRenderer input={inputs.subClassInput} component={SubClassInput} />
                            </Col>
                        </Row>
                    )}

                    <Row gutter={10}>
                        <Col style={StyleUtils.getTabColumnStyle(20)}>
                            <InputRenderer input={inputs.businessTypeInput} component={BusinessTypeInput} />
                        </Col>

                        <Col style={StyleUtils.getTabColumnStyle(20)}>
                            <InputRenderer input={inputs.typeOfParticipationInput} component={TypeOfParticipationInput} />
                        </Col>

                        <Col style={StyleUtils.getTabColumnStyle(20)}>
                            <InputRenderer input={inputs.underwritingYearInput} component={UnderwritingYearInput} />
                        </Col>

                        <Col style={StyleUtils.getTabColumnStyle(20)}>
                            <InputRenderer input={inputs.periodStartInput} component={PeriodStartInput} />
                        </Col>

                        <Col style={StyleUtils.getTabColumnStyle(20)}>
                            <InputRenderer input={inputs.periodEndInput} component={PeriodEndInput} />
                        </Col>
                    </Row>

                    <Row gutter={10}>
                        {inputs.countryGroupsInput && (
                            <Col style={StyleUtils.getTabColumnStyle(20)}>
                                <InputRenderer input={inputs.countryGroupsInput} component={CountryGroupsInput} />
                            </Col>
                        )}

                        {inputs.countriesInput && (
                            <Col style={StyleUtils.getTabColumnStyle(40)}>
                                <InputRenderer input={inputs.countriesInput} component={CountriesInput} />
                            </Col>
                        )}

                        <Col style={StyleUtils.getTabColumnStyle(20)}>
                            <InputRenderer input={inputs.lifecycleInput} component={LifecycleInput} />
                        </Col>

                        <Col style={StyleUtils.getTabColumnStyle(20)}>
                            <InputRenderer input={inputs.currencyInput} component={CurrencyInput} />
                        </Col>
                    </Row>

                    <Row gutter={10}>
                        {inputs.insuredInput && (
                            <Col style={StyleUtils.getTabColumnStyle(20)}>
                                <InputRenderer input={inputs.insuredInput} component={InsuredInput} />
                            </Col>
                        )}

                        <Col style={StyleUtils.getTabColumnStyle(20)}>
                            <InputRenderer input={inputs.reinsurerInput} component={ReinsurerInput} />
                        </Col>

                        <Col style={StyleUtils.getTabColumnStyle(20)}>
                            <InputRenderer input={inputs.reportUnitsInput} component={ReportUnitsInput} />
                        </Col>

                        {inputs.cedentInput && (
                            <Col style={StyleUtils.getTabColumnStyle(20)}>
                                <InputRenderer input={inputs.cedentInput} component={CedentInput} />
                            </Col>
                        )}

                        {inputs.brokerInput && (
                            <Col style={StyleUtils.getTabColumnStyle(20)}>
                                <InputRenderer input={inputs.brokerInput} component={BrokerInput} />
                            </Col>
                        )}
                    </Row>
                </TabCollapse>
            </Form>
        </div>
    );
};

export default observer(GeneralInformationTabRenderer);
