import { makeObservable, observable, action } from 'mobx';
import { BasicTabDto } from '../../types';
import { TabBaseModel, SourceModelBase, InputBaseModel } from '..';
import { InputFactory, UiUtils } from '../../misc';

export default class BasicTabModel extends TabBaseModel {
    inputs: InputBaseModel[] = [];

    expanded: boolean = true;

    constructor(data: BasicTabDto, sources: SourceModelBase[]) {
        super(data);

        this.inputs = InputFactory.createInputs(this, data.inputs, sources);

        makeObservable(this, {
            inputs: observable,
            expanded: observable,
            setExpanded: action.bound,
            toggleExpanded: action.bound,
            update: action
        });

        this.setExpanded(UiUtils.shouldExpandCollapse(this.inputs));
    }

    setExpanded(expanded: boolean) {
        this.expanded = expanded;
    }

    toggleExpanded() {
        this.setExpanded(!this.expanded);
    }

    update(data: BasicTabDto, sources: SourceModelBase[]) {
        this.inputs = InputFactory.createOrUpdateInputs(this, this.inputs, data.inputs, sources);
    }

    getInputsAtSameLevel() {
        return this.inputs;
    }

    get dto(): BasicTabDto {
        return {
            id: this.id,
            type: this.type,
            name: this.name,
            inputs: this.inputs.map(input => input.dto)
        };
    }
}
